import { type CaseData } from './index'

export const caseDataVillaLangaa: CaseData = {
  id: '1',
  title: 'Villa Langå',
  entries: [
    {
      imageAlt: 'home_exterior_southwest_far',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583422/naestarkitekter/cases/villa_langaa/large/home_exterior_southwest_far_wss5jy.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583422/naestarkitekter/cases/villa_langaa/large/home_exterior_southwest_far_wss5jy.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_interior_terrace_view',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583422/naestarkitekter/cases/villa_langaa/large/home_interior_terrace_view_fxxssy.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583422/naestarkitekter/cases/villa_langaa/large/home_interior_terrace_view_fxxssy.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_exterior_west_far',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583422/naestarkitekter/cases/villa_langaa/large/home_exterior_west_far_ujkfec.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583422/naestarkitekter/cases/villa_langaa/large/home_exterior_west_far_ujkfec.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_exterior_west_garage_meets_house',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583421/naestarkitekter/cases/villa_langaa/large/home_exterior_west_garage_meets_house_s3ttxn.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583421/naestarkitekter/cases/villa_langaa/large/home_exterior_west_garage_meets_house_s3ttxn.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_interior_kitchen',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583422/naestarkitekter/cases/villa_langaa/large/home_interior_kitchen_a5hqek.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583422/naestarkitekter/cases/villa_langaa/large/home_interior_kitchen_a5hqek.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_interior_kitchen_looking_out',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583421/naestarkitekter/cases/villa_langaa/large/home_interior_kitchen_looking_out_de4svw.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583421/naestarkitekter/cases/villa_langaa/large/home_interior_kitchen_looking_out_de4svw.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_exterior_southwest',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583421/naestarkitekter/cases/villa_langaa/large/home_exterior_southwest_tsvqcy.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583421/naestarkitekter/cases/villa_langaa/large/home_exterior_southwest_tsvqcy.jpg',
      text: ['']
    },

    {
      imageAlt: 'home_exterior_west_close',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583421/naestarkitekter/cases/villa_langaa/large/home_exterior_west_close_nfolk1.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583421/naestarkitekter/cases/villa_langaa/large/home_exterior_west_close_nfolk1.jpg',
      text: ['']
    },
    {
      imageAlt: 'home_exterior_front',
      thumbUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/c_thumb,w_400,g_face/v1698583420/naestarkitekter/cases/villa_langaa/large/home_exterior_front_zlwrzn.jpg',
      imageUrl: 'https://res.cloudinary.com/djlg9t2zf/image/upload/v1698583420/naestarkitekter/cases/villa_langaa/large/home_exterior_front_zlwrzn.jpg',
      text: ['']
    }

  ]
}
