import React from 'react'
import { ProcessMd } from './md'
import { ProcessXs } from './xs'

export const Process = () => {
  return (

    <>
      <ProcessXs/>
      <ProcessMd/>
    </>

  )
}
