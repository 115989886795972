import React from 'react'
import { NavBottomXs } from './display/xs'
import { NavBottomMd } from './display/md'

export const NavBottom = () => {
  return (
    <>
      <NavBottomXs />
      <NavBottomMd />
    </>
  )
}
