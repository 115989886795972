import React from 'react'

import { Box, Container, Stack, Typography } from '@mui/material'
import NestSvg from '../svg/nest'

export const ProfilePage = () => {
  return (

    <Container>

      <Box py={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Box height={40}>
          <NestSvg />
        </Box>
      </Box>

      <Stack spacing={2} pb={10}>

        <Typography>
                    NAEST arkitekter er skabt med kærlighed til boligen og redebyggeriet.
                    Grundlagt af de dedikerede arkitekter Andrea Louise Brøndsted og Line Toft
                    Jensen, er vores mission at skabe eller forvandle hver enkelt bolig til et unikt,
                    varmt og indbydende hjem.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
                    "Nest" - Et hjem, en historie, et liv
        </Typography>

        <Typography>
                    Ordet "nest" på engelsk betyder mere end blot et sted at bo; det repræsenterer
                    følelsen af sikkerhed, tryghed og tilhørsforhold. Hos NAEST arkitekter
                    trækker vi inspiration fra dette smukke begreb, samtidig med at vi hele tiden
                    har fokus på det næste skridt mod den nyeste viden indenfor sundhed,
                    samfund og materialer - deraf ordet NAEST. Vi stræber efter at skabe boliger,
                    der går ud over at være funktionelle, ved at bevare sjæl, karakter, historie og liv.
                    Hvert projekt er en mulighed for at forme en "nest," hvor vores kunder kan
                    skabe deres egne minder og oplevelser.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
                    Naturlige materialer, tidløs skønhed
        </Typography>

        <Typography>
                    Vores tilgang til arkitektur er kendetegnet ved brugen af robuste og naturlige
                    materialer. Vi tror på, at ægte skønhed og bæredygtighed kommer fra
                    materialer, der patinerer smukt over tid. Træ, sten, og metal er hjørnestenene i
                    vores designs, og de bringer naturen ind i dit hjem på en måde, der aldrig går af
                    mode og mister værdi.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
                    Skære bort for at skabe merværdi
        </Typography>

        <Typography>
                    I vores designfilosofi handler det ikke kun om at tilføje nyt, men også om at
                    skære det overflødige bort. Vi tror på, at mindre kan være mere, og at enkle,
                    velovervejede løsninger skaber en større merværdi for dig og din bolig. Vores
                    tilgang til arkitektur er præget af minimalisme og funktionalitet, der respekterer
                    stedets karakter, forudsætninger og historie.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
                    Hver bolig er en fortælling
        </Typography>

        <Typography>
                    Vi ser hver bolig som en unik fortælling. Vores dedikerede team af arkitekter og
                    samarbejdspartnere arbejder tæt sammen med dig for at forstå dine behov,
                    drømme og ønsker. Sammen skaber vi skræddersyede løsninger, der udtrykker
                    din personlige stil og skaber et hjem, der er mere end bare fire vægge.
        </Typography>

        <Typography>
                    Velkommen til NAEST arkitekter, hvor vi bygger "nests" fyldt med karakter,
                    historie og tidløs skønhed.
        </Typography>

        <Typography>
                    Lad os sammen forme dit drømmehjem!
        </Typography>

      </Stack>

    </Container>

  )
}
