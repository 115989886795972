import React, { type FC } from 'react'

export const ProcessDiagramContainer: FC<any> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1317 519"
      version="1.1"
      viewBox="0 0 1317 519"
      xmlSpace="preserve"
      style={{ height: '100%' }}

    >
      {props.children}
    </svg>

  )
}
