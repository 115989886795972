import { Stack, Typography } from '@mui/material'
import React from 'react'

export const ProcessText = () => {
  return (

    <Stack spacing={2} pb={4}>

      <Typography variant={'h6'} fontWeight={'bold'}>
        Procesforløb mod drømmehjemmet
      </Typography>

      <Typography>

        NAEST arkitekter hjælper dig med at tegne din kommende bolig, om- eller
        tilbygning.

      </Typography>

      <Typography>
        Vi har skabt et overblik over procesforløbet mod drømmehjemmet. Hver enkelt
        fase i processen kan ses som en selvstændig ydelse, eller du kan vælge at
        bruge os som rådgivere gennem hele forløbet. Byggerådgivning og udførelse
        bør dog være adskilt således, at der ikke opstår særinterresser hos en eventuel
        totalentreprenør
      </Typography>

    </Stack>

  )
}
