import { type PaletteMode, type ThemeOptions } from '@mui/material'

export const getThemeOptions = (mode: PaletteMode): ThemeOptions => ({
  typography: {
    fontFamily: [
      'Montserrat', 'sans-serif'
    ].join(',')
  },
  palette: {
    mode,
    primary: {
      main: '#f1f0e8'
    },
    secondary: {
      main: '#e8e9f1',

      contrastText: 'rgba(0, 0, 0, 0.87)'
    }
  }

})
