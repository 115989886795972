import React from 'react'
import { useTheme } from '@mui/material'

function SketchProposalCircle () {
  const theme = useTheme()

  return (
    <circle cx="389" cy="250" r="109" fill={theme.palette.background.default}></circle>
  )
}

export default SketchProposalCircle
