import { Box, ClickAwayListener, IconButton, Modal, Paper, SvgIcon } from '@mui/material'
import React, { useState } from 'react'
import { type CaseDataEntry } from '../../cases/data'
import Carousel from 'react-material-ui-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useHotkeys } from 'react-hotkeys-hook'

export interface CaseImagesModalProps {
  open?: boolean
  onCloseClick: () => void
  entries: CaseDataEntry[]
  index: number
}

const carouselPortionOfScreen = 90
const height = carouselPortionOfScreen + 'vh'
const width = carouselPortionOfScreen + 'vw'

export const CaseImagesModal = (props: CaseImagesModalProps) => {
  const {
    open = false,
    onCloseClick,
    entries,
    index
  } = props

  const [imgIndex, setImgIndex] = useState(index)

  useHotkeys('ArrowLeft', () => {
    setImgIndex(prev => (prev + entries.length - 1) % (entries.length))
  })

  useHotkeys('ArrowRight', () => {
    setImgIndex(prev => (prev + 1) % (entries.length))
  })

  return (
    <Modal open={open} onClose={onCloseClick}>
      <Box sx={{ height: '100vh', width: '100vw' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ClickAwayListener
          onClickAway={onCloseClick}
        >

          <Box sx={{ position: 'relative', width }}>

            <Carousel
              sx={{ width: '100%' }}
              autoPlay={false}
              navButtonsAlwaysVisible
              index={imgIndex}
            >
                {entries.map(caseEntry => (
                  <Paper
                    key={caseEntry.imageAlt}
                    sx={{ p: 1, width, height, display: ' flex', justifyContent: 'center' }}
                  >
                    <img
                      style={{ height: '100%', width: 'auto' }}
                      srcSet={caseEntry?.imageUrl}
                      src={caseEntry.imageUrl}
                      alt={caseEntry?.imageAlt}
                    />
                  </Paper>

                ))
                }
            </Carousel>

            <IconButton
              onClick={onCloseClick}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 9
              }}
            >
              <SvgIcon>
                <FontAwesomeIcon icon={faClose}/>
              </SvgIcon>
             </IconButton>

          </Box>
        </ClickAwayListener>
      </Box>

    </Modal>
  )
}
