import React, { useState } from 'react'
import { BottomNavigation, BottomNavigationAction, IconButton, Paper, Stack, SvgIcon } from '@mui/material'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBellConcierge, faCertificate, faPencil, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
// import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import { casesPathName, contactPathName, processPathName, profilePathName } from '../../../routes'
import { facebookSvgPath } from '../../icons/facebook'
import { instagramSvgPath } from '../../icons/instagram'

export const NavTopXs = () => {
  return (
    <Stack direction={'row'} spacing={2} sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton href={'https://www.facebook.com/naest.arkitekter'} target={'_blank'}>
        <SvgIcon fontSize={'small'} viewBox={'0 0 512 512'}>
          {facebookSvgPath}
        </SvgIcon>
      </IconButton>

      <IconButton href={'https://www.instagram.com/naestarkitekter'} target={'_blank'}>
        <SvgIcon fontSize={'small'} viewBox={'0 0 512 512'}>
          {instagramSvgPath}
        </SvgIcon>
      </IconButton>
    </Stack>
  )
}

export const NavBottomXs = () => {
  const [value, setValue] = useState()

  return (
    <Paper sx={{ display: { xs: 'block', md: 'none' }, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation

        sx={{ height: 64, '& .Mui-selected': { color: 'text.primary' } }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}

      >
        <BottomNavigationAction
          component={Link}
          to={profilePathName}
          label={'Profil'}
          // icon={<FontAwesomeIcon icon={faUserGraduate}/>}
        />
        {/* <BottomNavigationAction label={'Ydelser'} */}
        {/*  // icon={<FontAwesomeIcon icon={faBellConcierge}/>} */}
        {/* /> */}
        <BottomNavigationAction
          component={Link}
          to={processPathName}
          label={'Proces'}
          // icon={<FontAwesomeIcon icon={faPencil}/>}
        />
        <BottomNavigationAction
          component={Link}
          to={casesPathName}
          label={'Projekter'}
          // icon={<FontAwesomeIcon icon={faCertificate}/>}
        />
        <BottomNavigationAction
          component={Link}
          to={contactPathName}
          label={'Kontakt'}
          // icon={<FontAwesomeIcon icon={faEnvelope}/>}
        />
      </BottomNavigation>
    </Paper>
  )
}
