import { DenseListItem } from '../components/listItem'
import { List, ListItemText, Paper, Typography } from '@mui/material'
import React from 'react'

export interface ListBoxItem {
  header?: string
  bulletPoints: string[]
}

export interface ListBoxProps {
  items: ListBoxItem[]
}

export const ListBox = (props: ListBoxProps) => {
  const { items } = props

  const renderBulletPoint = (str: string, index: number) => {
    return (
      <DenseListItem key={str + index}>
        <ListItemText primary={str}/>
      </DenseListItem>
    )
  }

  const renderListItem = (item: ListBoxItem, index: number) => {
    return (
      <React.Fragment key={item.header ?? '' + index}>
        <Typography variant={'subtitle1'} fontWeight={'bold'}>
          {item.header}
        </Typography>
        <List dense>
          {item.bulletPoints.map(renderBulletPoint)}
        </List>
      </React.Fragment>
    )
  }

  return (
    <Paper sx={{ p: 2 }} elevation={3}>
      {items.map(renderListItem)}
    </Paper>
  )
}
