import React from 'react'
import { useTheme } from '@mui/material'

export const SketchProposalText = () => {
  const theme = useTheme()

  return (
    <text
      fontFamily="Montserrat, sans-serif"
      fontWeight="500"
      fontSize="18"
      transform="translate(312.46 258.579)"
      fill={theme.palette.text.primary}
      style={{ cursor: 'pointer' }}
    >
      SKITSEFORSLAG
    </text>

  )
}
