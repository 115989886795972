import React from 'react'
import { useTheme } from '@mui/material'

export const ExecutionOutline = () => {
  const theme = useTheme()

  return (
    <path
      stroke={theme.palette.text.primary}
      d="M1202 141.8c28.9 0 56.1 11.3 76.5 31.7s31.7 47.6 31.7 76.5-11.3 56.1-31.7 76.5-47.6 31.7-76.5 31.7-56.1-11.3-76.5-31.7-31.7-47.6-31.7-76.5 11.3-56.1 31.7-76.5c20.4-20.5 47.6-31.7 76.5-31.7m0-.8c-60.2 0-109 48.8-109 109s48.8 109 109 109 109-48.8 109-109-48.8-109-109-109z"
    ></path>
  )
}
