import { Typography } from '@mui/material'
import React from 'react'
import { TypographyJustified } from '../../../common/components/typography'
import { ListBox } from '../../../common/listBox'
import { ProcessPageTextContainer } from '../../../common/components/processPageTextContainer'

export const BuildingPermitParagraph = () => {
  return (

    <ProcessPageTextContainer

      prose={
        <>
          <Typography variant={'h6'} fontWeight={'bold'}>
          MYNDIGHEDSTEGNINGER TIL BYGGETILLADELSE
          </Typography>

          <TypographyJustified>

          På baggrund af skitserne tegner vi projektet mere præcist op. Tegninger
          forsynes med mål og nødvendige informationer, herunder beregning af
          bebyggelsesprocent, så kommunen nemt kan sagsbehandle din ansøgning.
          Vi sikrer, at regler i bygningsreglementet og evt. lokalplan/servitutter bliver
          overholdt.

          </TypographyJustified>

          <TypographyJustified>
          Ved de fleste renoveringer/ombygninger kan denne fase springes over.

          </TypographyJustified>

          <TypographyJustified>
          I visse tilfælde, kan det være en god idé at tilknytte en ingeniør eller landmåler,
          da nogle kommuner stiller krav om f.eks. fundamentsplaner, statiske- og
          energitabsberegninger. Dette er noget bygherre står for, men vi er gerne
          behjælpelig med kommunikationen. Bemærk at kommunens samlede
          godkendelse af et byggeprojekt falder i to omgange: Dels byggetilladelsen
          (som nævnt her), dels ibrugtagningstilladelsen ved færdigmelding.
          </TypographyJustified>
        </>
      }

      bulletPoints={
        <ListBox
          items={[
            {
              bulletPoints: [
                '5-7 A3 PDF-filer med myndighedstegningerne: Planer, snit og facader, samt situationsplan – både “før” og “efter”, samt beregning af arealer og bebyggelsesprocent',
                'Du får også et notat om projektets brandklasse og konstruktionsklasse som kommunen også skal bruge',
                'Desuden får du vores skriftlige vejledning til upload af ansøgningen til Bygogmiljø.dk',
                'Så er du klar til at uploade ansøgningen til kommunen'
              ]
            }
          ]}
        />
      }

    />

  )
}
