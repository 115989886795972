import { Typography } from '@mui/material'
import React from 'react'
import { TypographyJustified } from '../../../common/components/typography'
import { ListBox } from '../../../common/listBox'
import { ProcessPageTextContainer } from '../../../common/components/processPageTextContainer'

export const ExecutionParagraph = () => {
  return (

    <ProcessPageTextContainer

      prose={
        <>
          <Typography variant={'h6'} fontWeight={'bold'}>
            UDFØRELSE OG EVT. TILSYN OG KONTROL:
          </Typography>

          <TypographyJustified>
            Uanset hvor velrenommeret en tegnestue der står bag, bliver et byggeri aldrig bedre end de håndværkere, som udfører arbejdet.
            For at sikre dig den best mulige og mest detaljerede kontrol af håndværkernes
            udførelse, opfordrer vi dig til at kontakte og indgå en aftale med en
            byggesagkyndig som er specialist i netop at føre tilsyn med udførelsen. Vi
            hjælper ligeledes med at finde løsninger på muligt opståede problemer og
            eventuelle fejl og mangler.
          </TypographyJustified>

        </>
      }

      bulletPoints={
        <ListBox
          items={[
            {
              header: 'Vi tilbyder:',
              bulletPoints: [
                'Deltagelse i byggemøder i forbindelse med byggeriets start og i løbet af processen',
                'Dialog med entreprenøren i byggeprocessen. Hvis der skulle opstå problemer, løser vi det i fællesskab',
                'Løbende tilsyn af projektet, som udføres på udvalgte tidspunkter',
                'Som afslutning på byggeprocessen udfører vi en afleveringsgennemgang. I gennemgangen tjekker vi for fejl og mangler der skal udbedres inden aflevering'
              ]
            }
          ]}
        />

      }

    />

  )
}
