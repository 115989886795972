import React from 'react'
import { Box, Container, Stack, Typography } from '@mui/material'
import { type CaseData, type CaseDataEntry } from './data'
import { useLoaderData } from 'react-router-dom'
import { AlternatingRow } from './styled/alternatingRow'

export const ImageTextAlternating = ({ caseDataEntry }: { caseDataEntry: CaseDataEntry }) => {
  return (

    <AlternatingRow spacing={2}>

      <Box flex={1}>
        <img
          alt={caseDataEntry.imageAlt}
          src={process.env.PUBLIC_URL + '/images/' + caseDataEntry.imageUrl}

          style={{ width: '100%' }}
        />

      </Box>

      <Stack flex={1} spacing={1} p={2}>
        {caseDataEntry.text.map(t => {
          return (
            <Typography key={t.substring(0, 20)}>
              {t}
            </Typography>
          )
        })}
      </Stack>

    </AlternatingRow>
  )
}

export const Case = () => {
  const { caseData } = useLoaderData() as { caseData: CaseData }

  return (
    <>

      <Container>

        <Stack pt={2} spacing={2}>

          <Typography variant={'h5'}>
            {caseData.title}
          </Typography>

          {caseData.entries.map(x => {
            return (
              <ImageTextAlternating key={x.imageAlt} caseDataEntry={x} />
            )
          })}

        </Stack>

      </Container>

    </>
  )
}
