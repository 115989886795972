import React, { type FC, useState } from 'react'
import { Button, type ButtonProps, type SxProps } from '@mui/material'

export const ToolbarButton: FC<ButtonProps & { active?: boolean }> = (props) => {
  const { active, children, ...otherProps } = props

  const [hovered, setHovered] = useState(false)

  const borderSx: SxProps = {
    borderColor: !hovered && !active ? 'white' : undefined,
    '&.MuiButton-root': { borderColor: 'text.primary', border: hovered || active ? '1px solid' : '1px solid transparent' }
  }

  return <Button {...otherProps} variant={'outlined'} sx={{ color: 'text.primary', ...borderSx }}
    onMouseEnter={() => { setHovered(true) }} onMouseLeave={() => { setHovered(false) }}>
    {children}
  </Button>
}
