// import { useRouteError } from 'react-router-dom'
import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'

// interface RouteError {
//   statusText: string
//   message: string
// }

export default function ErrorPage () {
  // const error: RouteError = useRouteError()
  // console.error(error)

  return (
    <Container>
      <Box pt={8} display={'flex'} justifyContent={'center'}>
        <Stack spacing={2}>
          <Typography variant={'h1'}>
                        Oops!
          </Typography>

          <Typography variant={'subtitle1'}>
                        Sorry, an unexpected error has occurred.
          </Typography>

          {/* <Typography fontStyle={'italic'}> */}
          {/*  {error.statusText || error.message} */}
          {/* </Typography> */}
        </Stack>
      </Box>

    </Container>

  )
}
