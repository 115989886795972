import React from 'react'
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material'
import { NavTopMd } from './display/md'
import NaestLogoSvg from '../../svg/naestLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons'
import { ColorModeContext } from '../contexts/colorModeContext'
import { Link } from 'react-router-dom'
import { NavTopXs } from './display/xs'

export const NavTop = () => {
  const colorMode = React.useContext(ColorModeContext)

  return (

    <React.Fragment>
      <AppBar >
        <Toolbar>

          <Stack width={1} spacing={1} direction={'row'} alignItems={'center'}
            justifyContent={'space-between'}>

            <Link to={'/'}>
              <Box height={40}>
                <NaestLogoSvg />
              </Box>
            </Link>

            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <NavTopMd />
              <NavTopXs />

              <IconButton onClick={colorMode.toggleColorMode} color="inherit">
                <FontAwesomeIcon icon={faCircleHalfStroke} />
              </IconButton>

            </Stack>

          </Stack>

        </Toolbar>
      </AppBar>
      <Toolbar/>
    </React.Fragment>
  )
}
