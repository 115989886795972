import { type IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax'
import React, { useEffect, useRef, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import ProcessDiagram from '../../svg/processDiagram'
import SketchProposalOutline from '../../svg/processDiagramSketchCircle'
import { animated, useSpring } from '@react-spring/web'
import { ProcessDiagramContainer } from '../../svg/processDiagramContainer'
import { ProcessText } from './text'
import { PrerequisitesParagraph } from './text/prerequisites'
import { SketchProposalParagraph } from './text/sketchProposal'
import { BuildingPermitParagraph } from './text/buildingPermit'
import { MainProjectParagraph } from './text/mainProject'
import { ExecutionParagraph } from './text/execution'
import SketchProposalCircle from '../../svg/sketchProposalCircle'
import BuildingPermitCircle from '../../svg/buildingPermitCircle'
import MainProjectCircle from '../../svg/mainProjectCircle'
import ExecutionCircle from '../../svg/executionCircle'
import PrerequisitesCircle from '../../svg/prerequisitesCircle'
import { PrerequisitesOutline } from '../../svg/processDiagram/prerequisitesOutline'
import { BuildingPermitOutline } from '../../svg/processDiagram/buildingPermitOutline'
import { ExecutionOutline } from '../../svg/processDiagram/executionOutline'
import { MainProjectOutline } from '../../svg/processDiagram/mainProjectOutline'
import { MainProjectText } from '../../svg/processDiagram/mainProjectText'
import { PrerequisitesText } from '../../svg/processDiagram/prerequisitesText'
import { ExecutionText } from '../../svg/processDiagram/executionText'
import { BuildingPermitText } from '../../svg/processDiagram/buildingPermitText'
import { SketchProposalText } from '../../svg/processDiagram/sketchProposalText'
import { PrincipalDecisionText } from '../../svg/processDiagram/principalDecisionText'
import { FinalDecisionText } from '../../svg/processDiagram/finalDecisionText'

const prerequisitesOffset = 1
const sketchOffset = 3.5
const buildingPermitOffset = 6
const mainProjectOffset = 8.5
const executionOffset = 11

const parallaxStickyRange = 1

const offsets = [
  prerequisitesOffset,
  sketchOffset,
  buildingPermitOffset,
  mainProjectOffset,
  executionOffset
]

export const ProcessMd = () => {
  const theme = useTheme()

  const arrowsRotationStyles = useSpring({
    from: { rotate: 0 },
    to: { rotate: 360 },
    loop: true,
    config: { duration: 12000 }
  })

  const diagramHeight = '35vh'

  const strokeStyles = {
    strokeWidth: 2
  }

  const paddingCssProps = {
    paddingTop: diagramHeight,
    paddingRight: 32,
    paddingLeft: 32
  }

  const maxOffset = Math.max(...offsets)

  const PARALLAX_PAGES = maxOffset + 4

  const [reflectedParallaxPage, setReflectedParallaxPage] = useState(0)

  const [currentParallaxPage, setCurrentParallaxPage] = useState(0)

  const parallax = useRef<IParallax | null>(null)

  useEffect(() => {
    const handleScroll = (e: any) => {
      const height = parallax.current?.space ?? 0
      const scrollablePages = PARALLAX_PAGES - 1 // because you can't scroll past the last page
      const scrollHeight = height * scrollablePages
      const scrollTop = (e.target?.scrollTop || 0)
      const percentScrolled = scrollTop / scrollHeight
      const needle = percentScrolled * scrollablePages
      const currentPage = offsets.reduce((a, b) => {
        return Math.abs(b - needle) < Math.abs(a - needle) ? b : a
      })

      setReflectedParallaxPage(currentPage)
    }

    const container = parallax.current?.container.current
    if (!container) {
      return
    }

    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    parallax.current?.scrollTo(currentParallaxPage)
  }, [currentParallaxPage])

  const [hoveredPage, setHoveredPage] = useState(0)

  const cursorStyle = { cursor: 'pointer' }

  const highlight = (num: number) => [reflectedParallaxPage, hoveredPage].includes(num)

  const prerequisitesStyle = highlight(prerequisitesOffset) ? { ...strokeStyles } : {}
  const pagePathStyle2 = highlight(sketchOffset) ? { ...arrowsRotationStyles, ...strokeStyles } : {}
  const pageCircleStyle2 = reflectedParallaxPage ? { ...strokeStyles } : {}
  const buildingPermitStyles = highlight(buildingPermitOffset) ? { ...strokeStyles } : {}
  const mainProjectStyles = highlight(mainProjectOffset) ? { ...strokeStyles } : {}
  const executionStyles = highlight(executionOffset) ? { ...strokeStyles } : {}

  const parallaxSpeed = 0.5

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>

      <Box py={8} sx={{ position: 'relative', height: 'calc(100vh - 2 * 64px)' }}>

        <Parallax config={{}} ref={parallax} pages={PARALLAX_PAGES} style={{ top: '0', left: '0', bottom: 0 }}>

          <ParallaxLayer
            style={{
              paddingRight: 32,
              paddingLeft: 32,
              paddingTop: 64
            }}
            offset={0}
            speed={parallaxSpeed}
          >

            <ProcessText/>

          </ParallaxLayer>

          <ParallaxLayer
            offset={0.35}
            sticky={{ start: 0.35, end: maxOffset + 1 }}
            speed={1}
            style={{
              paddingRight: 32,
              paddingLeft: 32,
              height: diagramHeight,
              display: 'flex',
              justifyContent: 'center',
              background: theme.palette.background.default,
              zIndex: 1
            }}
          >

            <Box
              sx={{
                width: 1,
                display: 'flex',
                justifyContent: 'center',
                py: 2
              }}
            >
              <ProcessDiagramContainer>

                <animated.g
                  style={{ ...prerequisitesStyle, ...cursorStyle, transformOrigin: '118px 250px' }}
                  onClick={() => setCurrentParallaxPage(prerequisitesOffset)}
                  onMouseLeave={() => setHoveredPage(0)}
                  onMouseEnter={() => setHoveredPage(prerequisitesOffset)}
                >
                  <PrerequisitesCircle/>
                  <PrerequisitesOutline />
                  <PrerequisitesText />
                </animated.g>

                <animated.g onClick={() => setCurrentParallaxPage(sketchOffset)}
                  onMouseEnter={() => setHoveredPage(sketchOffset)}
                  onMouseLeave={() => setHoveredPage(0)}
                  style={{ ...pageCircleStyle2, ...cursorStyle }}
                >
                  <SketchProposalCircle/>
                  <SketchProposalText />
                </animated.g>

                <animated.g
                  style={{ ...pagePathStyle2, transformOrigin: '389px 250px' }}
                >
                  <SketchProposalOutline/>
                </animated.g>

                <animated.g onClick={() => setCurrentParallaxPage(buildingPermitOffset)}
                  onMouseEnter={() => setHoveredPage(buildingPermitOffset)}
                  onMouseLeave={() => setHoveredPage(0)}
                  style={{ ...buildingPermitStyles, ...cursorStyle, transformOrigin: '660px 250px' }}
                >
                  <BuildingPermitCircle/>
                  <BuildingPermitOutline />
                </animated.g>

                <animated.g onClick={() => setCurrentParallaxPage(mainProjectOffset)}
                  onMouseEnter={() => setHoveredPage(mainProjectOffset)}
                  onMouseLeave={() => setHoveredPage(0)}
                  style={{ ...mainProjectStyles, ...cursorStyle, transformOrigin: '931px 250px' }}

                >
                  <MainProjectCircle/>
                  <MainProjectOutline />
                </animated.g>

                <animated.g onClick={() => setCurrentParallaxPage(executionOffset)}
                  onMouseEnter={() => setHoveredPage(executionOffset)}
                  onMouseLeave={() => setHoveredPage(0)}
                  style={{ ...executionStyles, ...cursorStyle, transformOrigin: '1202px 250px' }}
                >
                  <ExecutionCircle/>
                  <ExecutionOutline />
                </animated.g>

                <ProcessDiagram/>

                <BuildingPermitText />
                <MainProjectText />
                <ExecutionText />
                <PrincipalDecisionText />
                <FinalDecisionText />

              </ProcessDiagramContainer>

            </Box>

          </ParallaxLayer>

          <ParallaxLayer
            style={paddingCssProps}
            sticky={{ start: prerequisitesOffset, end: prerequisitesOffset + parallaxStickyRange }}
            offset={prerequisitesOffset}
            speed={parallaxSpeed}
          >

            <animated.div>
              <PrerequisitesParagraph/>
            </animated.div>

          </ParallaxLayer>

          <ParallaxLayer
            style={paddingCssProps}
            offset={sketchOffset}
            sticky={{ start: sketchOffset, end: sketchOffset + parallaxStickyRange }}
            speed={parallaxSpeed}
          >

            <animated.div>
              <SketchProposalParagraph/>
            </animated.div>

          </ParallaxLayer>

          <ParallaxLayer
            style={paddingCssProps}
            offset={buildingPermitOffset}
            sticky={{ start: buildingPermitOffset, end: buildingPermitOffset + parallaxStickyRange }}
            speed={parallaxSpeed}
          >

            <animated.div>
              <BuildingPermitParagraph/>
            </animated.div>

          </ParallaxLayer>

          <ParallaxLayer
            style={paddingCssProps}
            offset={mainProjectOffset}
            sticky={{ start: mainProjectOffset, end: mainProjectOffset + parallaxStickyRange }}
            speed={parallaxSpeed}
          >

            <animated.div>
              <MainProjectParagraph/>
            </animated.div>

          </ParallaxLayer>

          <ParallaxLayer
            style={paddingCssProps}
            offset={executionOffset}
            sticky={{ start: executionOffset, end: executionOffset + parallaxStickyRange }}
            speed={parallaxSpeed}
          >

            <animated.div>
              <ExecutionParagraph/>
            </animated.div>

          </ParallaxLayer>

        </Parallax>

      </Box>

    </Box>
  )
}
