import { Box, Stack } from '@mui/material'
import React from 'react'
import { StyledDivider } from '../hr'

export interface ProcessPageTextContainerProps {
  prose: React.JSX.Element
  bulletPoints: React.JSX.Element

}

export const ProcessPageTextContainer = (props: ProcessPageTextContainerProps) => {
  return (
    <>
      <ProcessPageTextContainerXs {...props} />
      <ProcessPageTextContainerMd {...props} />
    </>
  )
}

export const ProcessPageTextContainerMd = (props: ProcessPageTextContainerProps) => {
  const { prose, bulletPoints } = props

  return (

    <Box sx={{ display: { xs: 'none', md: 'block' } }}>

      <Box py={1}>
        <StyledDivider/>
      </Box>

      <Stack pb={4} direction={'row'} spacing={8}>

        <Stack spacing={2} sx={{ flex: 3 }}>
          {prose}
        </Stack>

        <Box sx={{ flex: 2 }}>
          {bulletPoints}
        </Box>

      </Stack>

    </Box>

  )
}

export const ProcessPageTextContainerXs = (props: ProcessPageTextContainerProps) => {
  const { prose, bulletPoints } = props

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <Box py={1}>
        <StyledDivider/>
      </Box>

      <Stack pb={2} spacing={2}>

        <Box>
          {prose}
        </Box>

        <Box>
          {bulletPoints}
        </Box>

      </Stack>

    </Box>

  )
}
