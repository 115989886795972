import React from 'react'
import { useTheme } from '@mui/material'

function BuildingPermitCircle () {
  const theme = useTheme()

  return (
    <circle cx="660" cy="250" r="108.6" fill={theme.palette.background.default}></circle>
  )
}

export default BuildingPermitCircle
