import { Typography } from '@mui/material'
import React from 'react'
import { TypographyJustified } from '../../../common/components/typography'
import { ListBox } from '../../../common/listBox'
import { ProcessPageTextContainer } from '../../../common/components/processPageTextContainer'

export const MainProjectParagraph = () => {
  return (

    <ProcessPageTextContainer

      prose={
        <>
          <Typography variant={'h6'} fontWeight={'bold'}>
            HOVEDPROJEKT TIL INDHENTNING AF TILBUD:
          </Typography>

          <TypographyJustified>
            Dette indeholder blandt andet detaljetegninger, tilbudlister,
            arbejdsbeskrivelser og møder på grunden.
          </TypographyJustified>

          <TypographyJustified>
            Et hovedprojekt er næste skridt efter et udført myndighedprojekt.
            Hovedprojektet indeholder blandt andet det udbudsmateriale, som
            entreprenøren skal bruge for at kende omfanget af opgaven, og derudfra sætte
            et tilbud op.
          </TypographyJustified>

          <TypographyJustified>
            Allerede inden udformningen af udbudsmaterialet skal det aftales hvilken
            entrepriseform projektmaterialet skal tilrettelægges efter. Det handler om
            hvorvidt du selv ønsker at indgå seperat aftale med alle håndværkerne
            (fagentreprise) eller indgår aftale med en enkelt virksomhed, som selv har
            aftale med sine underentreprenører (hovedentreprise).
          </TypographyJustified>

          <TypographyJustified>
            Vi anbefaler klart hovedentreprise medmindre du selv har særlige kompetencer
            og erfaring med byggeledelse. Ved hovedentreprise vil hovedentreprenøren stå
            for byggeledelse på byggepladsen, mens vi som arkitekter tilbyder løbende
            tilsyn.
          </TypographyJustified>
        </>
      }

      bulletPoints={
        <ListBox
          items={[
            {
              header: 'Du får:',
              bulletPoints: [
                'Præcise og målsatte tegninger: Situationsplan, etageplaner, facadetegninger, snittegninger og detailtegninger. Afleveres som PDF-filer i A3/A4 format',
                'Beskrivelse (typisk 30-45 sider): Beskriver både byggeriets materialer/konstruktioner, samt vilkår for entreprenøren (byggepladsindretning m.v.), og aftalemæssige betingelser (tidsterminer, betalingsvilkår, dagbøder m.v.)',
                'Tilbudslister: Opbygges således, at der kommer delpriser ind på forskellige poster og evt. varianter, så du har mulighed for at til- og fravælge punkter og få den endelige projektøkonomi til at hænge sammen, også selvom de indkomne tilbudspriser måtte være for høje',
                'Udbudsbrev: Vigtige vilkår og tidsterminer for både udbudsfasen og udførelsen',
                'Indarbejdelse af input fra ingeniør i det samlede materiale',
                'Eventuel varmetabsberegning'
              ]
            }
          ]}
        />

      }

    />

  )
}
