import React from 'react'
import { Box } from '@mui/material'

export default function Home () {
  return (

    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: `url(${process.env.PUBLIC_URL + '/images/naest.jpg'}) no-repeat center center fixed`,
      backgroundSize: 'cover'
    }}>

    </Box>
  )
}
