import { Container, Stack } from '@mui/material'
import React from 'react'
import { ProcessText } from './text'
import { PrerequisitesParagraph } from './text/prerequisites'
import { SketchProposalParagraph } from './text/sketchProposal'
import { BuildingPermitParagraph } from './text/buildingPermit'
import { MainProjectParagraph } from './text/mainProject'
import { ExecutionParagraph } from './text/execution'

export const ProcessXs = () => {
  return (
    <Container sx={{ display: { xs: 'block', md: 'none' } }}>
      <Stack pt={2} pb={10} spacing={1}>

        <ProcessText/>
        <PrerequisitesParagraph/>
        <SketchProposalParagraph/>
        <BuildingPermitParagraph/>
        <MainProjectParagraph/>
        <ExecutionParagraph/>

      </Stack>
    </Container>
  )
}
