import { Stack, styled } from '@mui/material'

export const AlternatingRow = styled(Stack)({

  '&:nth-child(even)': {
    flexDirection: 'row'
  },
  '&:nth-child(odd)': {
    flexDirection: 'row-reverse'
  }

})
