import React from 'react'
import { useTheme } from '@mui/material'

function NaestLogoSvg () {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 980.6 392.1"
      viewBox="0 0 980.6 392.1"
      style={{ height: '100%' }}
      fill={theme.palette.text.primary }
    >
      <g display="none" >
        <path
          fill="none"
          d="M150.6 -80H795.6V95H150.6z"
          display="inline"
        ></path>
        <text
          display="inline"
          fontFamily="MyriadPro-Regular"
          fontSize="200"
          transform="translate(150.555 61.98)"
        >
          NAEST
        </text>
      </g>
      <g>
        <path d="M175.1 270.7L7 19.3 7 268.8 0 268.8 0 7.8 6.4 5.9 174.5 257.3 174.5 7.8 181.5 7.8 181.5 268.8z"></path>
        <path d="M225.5 269.9L218.9 267.6 309.2 6.7 315.8 6.7 402.1 267.7 395.4 269.9 312.4 18.7z"></path>
        <path d="M441 8.5H581.4V15.5H441z"></path>
        <path d="M441 130.9H581.4V137.9H441z"></path>
        <path d="M441 249.2H581.4V256.2H441z"></path>
        <path d="M872.8 12H879.8V268.8H872.8z"></path>
        <path d="M778 8.5H980.6V15.5H778z"></path>
        <path d="M685.1 266.3c-28.8 0-57.3-10.3-69.7-15.4l2.7-6.5c14.6 6.1 52.2 19.5 85 13.3 36.1-6.8 60.4-32.5 60.4-64 0-29.6-33.6-42.4-69.1-56-36.9-14.1-75.1-28.7-75.1-64.5 0-21.4 9.3-39.4 26.8-52.2 13.3-9.6 31.2-15.9 47.9-16.7 38.7-1.9 63.6 11.8 64.7 12.4l-3.4 6.1c-.2-.1-24.3-13.3-60.9-11.5-27.3 1.3-68 18.9-68 61.8 0 30.9 34.3 44.1 70.6 58 36.2 13.8 73.6 28.2 73.6 62.5 0 34.9-26.6 63.3-66.1 70.8-6.5 1.4-12.9 1.9-19.4 1.9zM503.1 341.8v48.5h-7.3V382c-6.6 6.6-14.1 10-22.6 10-8.5 0-15.6-2.5-21.5-7.6-5.8-5.1-8.8-11.1-8.8-18.2 0-7 2.9-13.1 8.8-18.1 5.9-5 12.9-7.5 21.1-7.5 9.4 0 17.1 3.4 22.9 10.1v-8.9h7.4zm-6.9 24.5c0-5.5-2.2-10.2-6.6-14-4.4-3.8-9.8-5.7-16.3-5.7-6.4 0-11.8 2-16.3 5.9-4.4 3.9-6.6 8.6-6.6 13.9 0 5.4 2.2 10 6.7 13.9 4.5 3.9 9.9 5.9 16.1 5.9 6.3 0 11.6-1.9 16.1-5.7 4.7-3.7 6.9-8.5 6.9-14.2z"></path>
        <path d="M473.2 392.1c-8.5 0-15.7-2.6-21.6-7.7-5.8-5.1-8.8-11.2-8.8-18.3 0-7 3-13.1 8.8-18.2 5.9-5 13-7.6 21.2-7.6 9.3 0 17 3.3 22.8 9.9v-8.7h7.5v48.7h-7.5v-8.1c-6.5 6.7-14 10-22.4 10zm-.3-51.4c-8.1 0-15.2 2.5-21 7.5-5.8 5-8.8 11-8.8 18s2.9 13 8.7 18.1c5.8 5 13 7.6 21.4 7.6 8.4 0 16-3.3 22.5-9.9l.2-.2v8.5h7v-48.2h-7v9.1l-.2-.3c-5.8-6.8-13.5-10.2-22.8-10.2zm.4 45.6c-6.3 0-11.7-2-16.2-5.9-4.5-3.9-6.8-8.6-6.8-14s2.2-10.1 6.7-14c4.4-3.9 9.9-5.9 16.3-5.9s11.9 1.9 16.3 5.7c4.4 3.8 6.7 8.6 6.7 14.1 0 5.7-2.3 10.6-6.8 14.3-4.4 3.8-9.9 5.7-16.2 5.7zm0-39.6c-6.4 0-11.8 2-16.2 5.9-4.4 3.9-6.6 8.5-6.6 13.8 0 5.3 2.3 9.9 6.7 13.8 4.4 3.9 9.9 5.9 16.1 5.9 6.2 0 11.6-1.9 16.1-5.6 4.4-3.7 6.7-8.5 6.7-14.1 0-5.5-2.2-10.2-6.6-13.9-4.4-3.9-9.8-5.8-16.2-5.8zM523.4 341.8h7.6v7c2-2.9 4-5 5.9-6.3 1.9-1.3 4.1-2 6.4-2 2.4 0 4.7.5 7.1 1.6l-3.9 5.3c-1.1-.5-2.2-.8-3.4-.8-2.2 0-4.4 1-6.4 2.9s-3.5 4.5-4.4 7.5c-.9 3.1-1.4 8.7-1.4 16.8v16.4h-7.6v-48.4z"></path>
        <path d="M531.1 390.4h-7.9v-48.7h7.9v6.8c1.9-2.7 3.9-4.7 5.7-6 1.9-1.3 4.1-2 6.5-2 2.4 0 4.8.6 7.2 1.7l.1.1-4.1 5.5h-.1c-1.1-.5-2.2-.8-3.3-.8-2.2 0-4.3 1-6.3 2.9s-3.4 4.4-4.4 7.5c-.9 3-1.4 8.7-1.4 16.7v16.3zm-7.6-.2h7.4v-16.3c0-8.1.5-13.7 1.4-16.8.9-3.1 2.4-5.6 4.4-7.6 2-2 4.2-3 6.4-3 1.1 0 2.3.3 3.3.8l3.8-5.1c-2.3-1-4.6-1.6-6.9-1.6-2.3 0-4.5.7-6.3 2-1.9 1.3-3.9 3.4-5.9 6.3l-.2.3v-7.3h-7.4v48.3zM564.3 323.1h7.5v38.3l26.6-19.5h11L577.7 365l33.6 25.4h-10.5l-28.9-21.8v21.8h-7.5v-67.3z"></path>
        <path d="M611.6 390.4h-11l-28.7-21.7v21.7h-7.8V323h7.8v38.1l26.5-19.4h11.4l-.3.2-31.6 23 33.7 25.5zm-10.9-.2h10.1l-33.4-25.3.1-.1 31.4-22.9h-10.6l-26.8 19.7v-38.4h-7.3v67h7.3v-21.9l.2.2 29 21.7zM622.9 328.3c0-1.4.6-2.6 1.8-3.7 1.2-1 2.6-1.6 4.3-1.6 1.7 0 3.2.5 4.3 1.5 1.2 1 1.8 2.2 1.8 3.7 0 1.4-.6 2.6-1.8 3.7-1.2 1-2.6 1.5-4.3 1.5-1.7 0-3.2-.5-4.3-1.6-1.2-1-1.8-2.2-1.8-3.5zm2.4 13.5h7.5v48.5h-7.5v-48.5z"></path>
        <path d="M632.9 390.4h-7.8v-48.7h7.8v48.7zm-7.5-.2h7.2V342h-7.2v48.2zm3.6-56.7c-1.7 0-3.2-.5-4.4-1.6-1.2-1.1-1.8-2.3-1.8-3.7 0-1.4.6-2.7 1.8-3.7 1.2-1.1 2.7-1.6 4.4-1.6s3.2.5 4.4 1.5c1.2 1 1.8 2.3 1.8 3.7 0 1.5-.6 2.7-1.8 3.7-1.2 1.2-2.7 1.7-4.4 1.7zm0-10.3c-1.7 0-3.1.5-4.3 1.5-1.2 1-1.8 2.2-1.8 3.6 0 1.3.6 2.5 1.8 3.5 1.2 1 2.6 1.5 4.3 1.5s3.1-.5 4.3-1.5c1.2-1 1.7-2.2 1.7-3.6s-.6-2.6-1.7-3.6c-1.2-.9-2.6-1.4-4.3-1.4zM668.5 382.6c0 1.6.3 2.6.8 2.9.5.3 1.8.5 3.9.5h4.5v5.3c-3 .4-5.4.6-7.2.6-3.3 0-5.7-.7-7.2-2s-2.3-3.4-2.3-6.3v-36.5h-10.2v-5.3H661v-18.7h7.5v18.7h11.7v5.3h-11.7v35.5z"></path>
        <path d="M670.5 392.1c-3.3 0-5.7-.7-7.3-2-1.5-1.4-2.3-3.5-2.3-6.4v-36.4h-10.2v-5.5h10.2V323h7.8v18.7h11.7v5.5h-11.7v35.4c0 1.6.2 2.5.7 2.8.5.3 1.8.4 3.8.4h4.6v5.5h-.1c-3.1.6-5.5.8-7.2.8zM650.9 347h10.2v36.6c0 2.8.8 4.9 2.2 6.2 1.5 1.3 3.9 2 7.1 2 1.7 0 4.1-.2 7-.6v-5.1h-4.3c-2.1 0-3.4-.2-4-.5-.6-.3-.8-1.3-.8-3V347H680v-5h-11.7v-18.7h-7.2V342h-10.2v5z"></path>
        <g>
          <path d="M746.5 374.6l6.3 2.7c-6.2 9.7-15.4 14.6-27.6 14.6-8.7 0-15.9-2.5-21.5-7.5-5.7-5-8.5-11.1-8.5-18.1 0-7.1 2.8-13.1 8.5-18.2s12.7-7.6 21.1-7.6c8.4 0 15.4 2.3 21.1 7 5.7 4.7 8.6 11.1 8.9 19.2h-52c.2 5.8 2.4 10.5 6.6 14.1 4.2 3.6 9.2 5.3 15.2 5.3 9.7.1 17-3.8 21.9-11.5zm0-13.2c-1-4.6-3.6-8.2-7.6-10.9-4.1-2.6-8.7-3.9-14-3.9-10.9 0-18 4.9-21.3 14.8h42.9z"></path>
          <path d="M725.2 392.1c-8.7 0-16-2.5-21.6-7.6-5.7-5-8.5-11.2-8.5-18.2s2.9-13.2 8.6-18.3c5.7-5.1 12.8-7.6 21.2-7.6 8.4 0 15.5 2.4 21.2 7.1 5.7 4.7 8.7 11.2 8.9 19.3v.1h-52c.2 5.7 2.4 10.4 6.5 13.9s9.2 5.3 15.1 5.3c9.7 0 17-3.9 21.9-11.5l.1-.1 6.5 2.8-.1.1c-6.4 9.7-15.7 14.7-27.8 14.7zm-.4-51.4c-8.3 0-15.4 2.5-21 7.5s-8.5 11.1-8.5 18.1 2.8 13.1 8.4 18c5.6 5 12.8 7.5 21.4 7.5 12 0 21.2-4.9 27.4-14.4l-6.1-2.6c-4.9 7.7-12.3 11.6-22 11.6-5.9 0-11.1-1.8-15.2-5.4-4.2-3.6-6.4-8.3-6.6-14.2v-.1h52c-.2-8-3.2-14.3-8.8-19-5.7-4.7-12.7-7-21-7zm21.8 20.8h-43.3l.1-.2c3.3-9.9 10.5-14.9 21.4-14.9 5.3 0 10 1.3 14.1 3.9 4.1 2.6 6.6 6.3 7.7 10.9v.3zm-42.9-.3h42.6c-1-4.5-3.6-8.1-7.5-10.6-4-2.6-8.7-3.9-14-3.9-10.7 0-17.8 4.9-21.1 14.5z"></path>
        </g>
        <g>
          <path d="M773.9 323.1h7.5v38.3l26.6-19.5h11L787.3 365l33.6 25.4h-10.5l-28.9-21.8v21.8H774v-67.3z"></path>
          <path d="M821.2 390.4h-11l-28.7-21.7v21.7h-7.8V323h7.8v38.1l26.5-19.4h11.4l-.3.2-31.6 23 33.7 25.5zm-10.9-.2h10.1L787 364.9l.1-.1 31.4-22.9H808l-26.8 19.7v-38.4H774v67h7.2v-21.9l.2.2 28.9 21.7z"></path>
        </g>
        <g>
          <path d="M849.9 382.6c0 1.6.3 2.6.8 2.9.5.3 1.8.5 3.9.5h4.5v5.3c-3 .4-5.4.6-7.2.6-3.3 0-5.7-.7-7.2-2s-2.3-3.4-2.3-6.3v-36.5h-10.2v-5.3h10.2v-18.7h7.5v18.7h11.7v5.3h-11.7v35.5z"></path>
          <path d="M851.9 392.1c-3.3 0-5.7-.7-7.3-2-1.5-1.4-2.3-3.5-2.3-6.4v-36.4h-10.2v-5.5h10.2V323h7.8v18.7h11.7v5.5h-11.7v35.4c0 1.6.2 2.5.7 2.8.5.3 1.8.4 3.8.4h4.6v5.5h-.1c-3 .6-5.5.8-7.2.8zM832.4 347h10.2v36.6c0 2.8.8 4.9 2.2 6.2 1.5 1.3 3.9 2 7.1 2 1.7 0 4.1-.2 7-.6v-5.1h-4.3c-2.1 0-3.4-.2-4-.5-.6-.3-.8-1.3-.8-3V347h11.7v-5h-11.7v-18.7h-7.3V342h-10.2v5z"></path>
        </g>
        <g>
          <path d="M927.9 374.6l6.3 2.7c-6.2 9.7-15.4 14.6-27.6 14.6-8.7 0-15.9-2.5-21.5-7.5s-8.5-11.1-8.5-18.1c0-7.1 2.8-13.1 8.5-18.2s12.7-7.6 21.1-7.6c8.4 0 15.4 2.3 21.1 7 5.7 4.7 8.6 11.1 8.9 19.2h-52c.2 5.8 2.4 10.5 6.6 14.1 4.2 3.6 9.2 5.3 15.2 5.3 9.7.1 17-3.8 21.9-11.5zm0-13.2c-1-4.6-3.6-8.2-7.6-10.9-4.1-2.6-8.7-3.9-14-3.9-10.9 0-18 4.9-21.3 14.8h42.9z"></path>
          <path d="M906.6 392.1c-8.7 0-16-2.5-21.6-7.6-5.7-5-8.5-11.2-8.5-18.2s2.9-13.2 8.6-18.3c5.7-5.1 12.8-7.6 21.2-7.6 8.4 0 15.5 2.4 21.2 7.1 5.7 4.7 8.7 11.2 8.9 19.3v.1h-52c.2 5.7 2.4 10.4 6.5 13.9s9.2 5.3 15.1 5.3c9.7 0 17-3.9 21.9-11.5l.1-.1 6.5 2.8-.1.1c-6.3 9.7-15.7 14.7-27.8 14.7zm-.4-51.4c-8.3 0-15.4 2.5-21 7.5s-8.5 11.1-8.5 18.1 2.8 13.1 8.4 18c5.6 5 12.8 7.5 21.4 7.5 12 0 21.2-4.9 27.4-14.4l-6.1-2.6c-4.9 7.7-12.3 11.6-22 11.6-5.9 0-11.1-1.8-15.2-5.4-4.2-3.6-6.4-8.3-6.6-14.2v-.1h52c-.2-8-3.2-14.3-8.8-19-5.6-4.7-12.7-7-21-7zm21.8 20.8h-43.3l.1-.2c3.3-9.9 10.5-14.9 21.4-14.9 5.3 0 10 1.3 14.1 3.9 4.1 2.6 6.6 6.3 7.7 10.9v.3zm-42.9-.3h42.6c-1-4.5-3.6-8.1-7.5-10.6-4-2.6-8.7-3.9-14-3.9-10.6 0-17.8 4.9-21.1 14.5z"></path>
        </g>
        <g>
          <path d="M953.5 341.8h7.6v7c2-2.9 4-5 5.9-6.3 1.9-1.3 4.1-2 6.4-2 2.4 0 4.7.5 7.1 1.6l-3.9 5.3c-1.1-.5-2.2-.8-3.4-.8-2.2 0-4.4 1-6.4 2.9s-3.5 4.5-4.4 7.5c-.9 3.1-1.4 8.7-1.4 16.8v16.4h-7.6v-48.4z"></path>
          <path d="M961.3 390.4h-7.9v-48.7h7.9v6.8c1.9-2.7 3.9-4.7 5.7-6 1.9-1.3 4.1-2 6.5-2 2.4 0 4.8.6 7.2 1.7l.1.1-4.1 5.5h-.1c-1.1-.5-2.2-.8-3.3-.8-2.2 0-4.3 1-6.3 2.9s-3.4 4.4-4.4 7.5c-.9 3-1.4 8.7-1.4 16.7v16.3zm-7.6-.2h7.4v-16.3c0-8.1.5-13.7 1.4-16.8.9-3.1 2.4-5.6 4.4-7.6 2-2 4.2-3 6.4-3 1.1 0 2.3.3 3.3.8l3.8-5.1c-2.3-1-4.6-1.6-6.9-1.6-2.3 0-4.5.7-6.3 2-1.9 1.3-3.9 3.4-5.9 6.3l-.2.3v-7.3h-7.4v48.3z"></path>
        </g>
      </g>
    </svg>
  )
}

export default NaestLogoSvg
