import { Box, ImageList, ImageListItem } from '@mui/material'
import React, { useState } from 'react'
import { type CaseData, type CaseDataEntry } from '../../cases/data'
import { CaseImagesModal } from './modal'

export interface CasesImageGridProps {
  data: CaseData
}

export const CasesImageGrid = (props: CasesImageGridProps) => {
  const { data } = props

  // const escapePressed = useShortcut({
  //   keysToListenTo: ['Alt'],
  //   eventName: 'CloseModalOnEscape'
  // })

  const [modalOpen, setModalOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const closeModal = () => setModalOpen(false)
  // if (escapePressed) closeModal()

  const openModal = (entry: CaseDataEntry) => {
    setModalOpen(true)
    setIndex(data.entries.indexOf(entry))
  }

  return (
    <Box pb={8}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {
          data.entries.map((entry) => (
            <ImageListItem
              key={entry.imageUrl}
              sx={{ cursor: 'pointer' }}
              onClick={() => openModal(entry)}>
              <img
                srcSet={entry.thumbUrl}
                src={entry.thumbUrl}
                alt={entry.imageAlt}
                loading="lazy"
              />
            </ImageListItem>
          ))
        }
      </ImageList>

      <CaseImagesModal
        open={modalOpen}
        onCloseClick={closeModal}
        entries={data.entries}
        index={index}
      />
    </Box>
  )
}
