import { Container, Stack, Typography } from '@mui/material'
import { caseDataVillaLangaa } from '../cases/data/villa_langaa'
import { CasesImageGrid } from './components/casesImageGrid'
import React from 'react'

export const CasesList = () => {
  const cases = [caseDataVillaLangaa]

  const renderCases = () => cases.map(x => (
    <Stack key={x.title} spacing={2}>
      <Typography variant={'h5'}>{x.title}</Typography>
      <CasesImageGrid key={x.title} data={x} />
    </Stack>
  ))

  return (
    <Container>
      <Stack py={4} spacing={2}>
        {renderCases()}
      </Stack>
    </Container>

  )
}
