import { Typography } from '@mui/material'
import React from 'react'
import { TypographyJustified } from '../../../common/components/typography'
import { ListBox } from '../../../common/listBox'
import { ProcessPageTextContainer } from '../../../common/components/processPageTextContainer'

export const SketchProposalParagraph = () => {
  return (

    <ProcessPageTextContainer

      prose={
        <>
          <Typography variant={'h6'} fontWeight={'bold'}>
            SKITSEFORSLAG
          </Typography>

          <TypographyJustified>
            Formålet med skitseforslaget er blandt andet at skabe overblik over
            mulighederne. Vi hjælper jer med at samle jeres ønsker og drømme til jeres
            kommende bolig i et konkret forslag, hvor der er tænkt over optimering af kvm,
            lysforhold, udsigt, bevægelsesmønstre samt økonomi.
          </TypographyJustified>

          <TypographyJustified>
            Igennem håndtegninger, planløsning og en tæt dialog med jer, til- og fravælges
            de bedste idéer, der er blevet præsenteret i skitseforslaget.
          </TypographyJustified>

        </>
      }

      bulletPoints={
        <ListBox
          items={[
            {
              bulletPoints: [
                'Et samlet beslutningsgrundlag, før projektet kommer for langt',
                'Gode idéer som er funktionelle, flotte, stedsspecifikke og overholder gældende regler',
                '3D-visualiseringer',
                'Estimeret tidsplan'
              ]
            }
          ]}
        />
      }

    />

  )
}
