import React from 'react'
import { Typography } from '@mui/material'
import { TypographyJustified } from '../../../common/components/typography'
import { ListBox, type ListBoxItem } from '../../../common/listBox'
import { ProcessPageTextContainer } from '../../../common/components/processPageTextContainer'

export const PrerequisitesParagraph = () => {
  const items: ListBoxItem[] = [
    {
      header: 'Forberedelse',
      bulletPoints: [
        'Tilgængelige og relevante oplysninger om boligen',
        'Gældende byggeregler',
        'Lokalplan',
        'Grund- og bygningsstørrelse',
        'Bevaringskategori ift. Kulturstyrelsen'
      ]
    },
    {
      header: 'Første møde hos jer',
      bulletPoints: [
        'Gennemgang af jeres bolig',
        'Gennemgang af jeres behov, ønsker, krav, prioriteringer og budget',
        'Skitsering af indledende idéer og muligheder',
        'Indblik i vores proces og rådgivning',
        'Indblik i byggeproces, tidsramme og overblik over økonomien for'
      ]
    }
  ]

  return (

    <ProcessPageTextContainer

      prose={
        <>
          <Typography variant={'h6'} fontWeight={'bold'}>
            FORUDSÆTNINGER
          </Typography>

          <TypographyJustified>
            Det vigtigste skridt i processen er at afklare hvilke behov der er tale
            om, og samtidigt hvilke forudsætninger der gør sig gældende på byggegrunden.
            Der kan være juridiske benspænd for visse boligdrømme, så det er en fordel at lade drømmene tage udgangspunkt
            i det mulige og tænke de fastlagte greb med på rejsen til endelig form.
          </TypographyJustified>

          <TypographyJustified>
            Derudover er det vigtigt at
            understrege at behov ikke nødvendigvis er det samme som løsning; Man kan
            f.eks. tro at man har behov for et ekstra værelse i form af en tilbygning, men
            måske kan det løses ved en indvendig ombygning. Der kan dermed potentielt
            være mange penge at spare ved den rigtige rådgivning.
          </TypographyJustified>

          <TypographyJustified>
            Stedets betingelser, begrænsninger og muligheder er afgørende
            for hvordan boligen udformer sig!
          </TypographyJustified>

        </>
      }

      bulletPoints={
        <ListBox items={items}/>
      }

    />

  )
}
