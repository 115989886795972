import React from 'react'
import { useTheme } from '@mui/material'

function NestSvg () {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      viewBox="0 0 421.17 244.48"
      style={{ height: '100%' }}
    >
      <path
        fill="none"
        stroke={theme.palette.text.primary}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M147.5 34.84s42.33 18.66 218 17.33"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M90.5 120.53s153-13.53 326.16-116"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M12.5 25.17s140 91.33 396.66 67.33"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M61.16 79.17S184.5 166.5 373.83 164.5"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M35.16 157.84s125.34-.67 270.67-40.67"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M4.5 73.17s112 123.33 315.33 146"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M126.5 189.84s170-8.67 274.66-63.34"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M23.16 52.24s7.34 103.6 78 151.6 124 36.66 162.67 30"
      ></path>
      <path
        fill="none"
        stroke={theme.palette.text.primary}

        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M210.83 222.5s79.67 4.74 139-58 55.33-121 55.33-121"
      ></path>
    </svg>
  )
}

export default NestSvg
