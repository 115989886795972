import React from 'react'
import { useTheme } from '@mui/material'

export const PrerequisitesOutline = () => {
  const theme = useTheme()

  return (
    <path
      stroke={theme.palette.text.primary}
      d="M118 141.8c28.9 0 56.1 11.3 76.5 31.7s31.7 47.6 31.7 76.5-11.3 56.1-31.7 76.5-47.6 31.7-76.5 31.7-56.1-11.2-76.5-31.7C21 306.1 9.8 278.9 9.8 250s11.3-56.1 31.7-76.5c20.4-20.5 47.6-31.7 76.5-31.7m0-.8C57.8 141 9 189.8 9 250s48.8 109 109 109 109-48.8 109-109-48.8-109-109-109z"
    ></path>
  )
}
