import { Box, styled } from '@mui/material'
import React from 'react'

export const StyledDividerMaterial = () => {
  return (
    <Box py={2}>
      <StyledDivider />
    </Box>
  )
}

export const StyledDivider = styled('hr')(({ theme }) => ({

  overflow: 'visible', /* For IE */
  padding: 0,
  border: 'none',
  borderTop: `medium double ${theme.palette.text.primary}`,
  color: theme.palette.text.primary,
  textAlign: 'center',

  '&:after': {
    content: '"§"',
    display: 'inline-block',
    position: 'relative',
    top: ' -0.85em',
    fontSize: '1.2em',
    padding: '0 0.25em',
    background: theme.palette.background.default
  }

}))
