import React from 'react'
import { useTheme } from '@mui/material'

function ProcessDiagram () {
  const theme = useTheme()
  return (

    <g>

      <path
        stroke={ theme.palette.text.primary}
        strokeDasharray="100,10"
        strokeMiterlimit="10"
        strokeWidth="1"
        d="M240 252L253 252 253 247 266 254 253 261 253 256 240 256z"
      ></path>
      <path
        stroke={theme.palette.text.primary}
        strokeDasharray="100,10"
        strokeMiterlimit="10"
        strokeWidth="1"
        d="M512 252L525 252 525 247 538 254 525 261 525 256 512 256z"
      ></path>
      <path
        stroke={theme.palette.text.primary}
        strokeDasharray="100,10"
        strokeMiterlimit="10"
        strokeWidth="1"
        d="M784 252L797 252 797 247 810 254 797 261 797 256 784 256z"
      ></path>
      <path
        stroke={theme.palette.text.primary}
        strokeDasharray="100,10"
        strokeMiterlimit="10"
        strokeWidth="1"
        d="M1055 252L1068 252 1068 247 1081 254 1068 261 1068 256 1055 256z"
      ></path>
    </g>
  )
}

export default ProcessDiagram
