import { caseDataVillaLangaa } from './villa_langaa'

export interface CaseData {
  id: string
  title: string
  entries: CaseDataEntry[]
}

export interface CaseDataEntry {
  imageAlt: string
  thumbUrl: string
  imageUrl: string
  text: string[]
}

export const caseDataList: CaseData[] = [caseDataVillaLangaa]
